export default function Skills() {
  return (
    <>
      <section id="skills">
        <h1>
          This is Skills section.
        </h1>
      </section>
    </>
  )
}