export default function Contact() {
  return(
    <>
      <section id="contact">
        <h1>
          This is Contact section.
        </h1>
        <a href="#">
          <div className="scroll-up">Scroll Up ↑</div>
        </a>
      </section>
    </>
  )
}